import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useEffect, useState, useTransition } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useSearch } from '../../SearchProvider/SearchProvider_Context';

const useLocationFilter = () => {
  const { isLocationOpen, toggleLocationFilter, toggleGuestFilter, search } = useSearch();
  const searchParams = useSearchParams();

  const [states, setStates] = useState(() => searchParams.get('states')?.split(',') || []);

  const resetFilters = () => {
    setStates([]);
    const params = new URLSearchParams(searchParams);
    params.delete('states');
    search(`?${params}`);
  };

  const handleSubmit = useDebouncedCallback(() => {
    const params = new URLSearchParams(searchParams);
    if (states.length === 0) {
      params.delete('states');
    } else {
      params.set('states', states.join(','));
    }
    search(`?${params}`);
  }, 500);

  const toggleLocation = (state: string) => {
    setStates((states) => (states.includes(state) ? states.filter((s) => s !== state) : [...states, state]));
    handleSubmit();
  };

  return {
    isLocationOpen,
    toggleLocationFilter,
    toggleLocation,
    states,
    resetFilters,
    handleSubmit: toggleGuestFilter,
  };
};

export default useLocationFilter;
