'use client';

import Icon from '@wander/ui/src/components/Icon';
import Input from '@wander/ui/src/components/Input/Input';
import clx from '@wander/ui/src/utils/clx';
import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';

const AiSearch_Input = ({
  inputRef,
  onClear,
  ...props
}: React.ComponentProps<typeof Input> & {
  inputRef: React.RefObject<HTMLInputElement>;
  onClear?: () => void;
}) => {
  const searchParams = useSearchParams();

  useEffect(() => {
    if (inputRef.current) {
      if (searchParams.has('search')) {
        inputRef.current.value = searchParams.get('search') ?? '';
      }
    }
  }, [searchParams]);
  return (
    <>
      <Input innerRef={inputRef} name='search' autoComplete='off' {...props} />
      <button
        type='button'
        className={clx(
          'grid aspect-square h-6 w-6 place-items-center rounded-md bg-w-8002 from-[#292929] to-[#2E2E2E] text-w-3005 transition-all hover:!bg-gradient-to-b',
          'group-has-[input:placeholder-shown]:hidden'
        )}
        onClick={() => {
          if (inputRef.current) {
            inputRef.current.value = '';
            inputRef.current.form?.requestSubmit();
            onClear?.();
          }
        }}
      >
        <Icon name='close' className='top-0 h-4 w-4' />
      </button>
    </>
  );
};

export default AiSearch_Input;
