'use client';

import { useCallback, useEffect, useImperativeHandle, useRef } from 'react';

import clx from '../../utils/clx';
import yieldToMainThread from '../../utils/yieldToMainThread';
// @ts-ignore
import style from './style.module.css';

const useNavBar = (disabled = false) => {
  const tabListRef = useRef<HTMLDivElement>(null);
  const currentTabElementRef = useRef<HTMLButtonElement>(null);

  const moveTo = useCallback((newTab: HTMLElement, callback?: () => void) => {
    const tabsContainer = tabListRef.current;

    if (!tabsContainer || !newTab) {
      return;
    }

    const oldTab = currentTabElementRef.current;

    if (oldTab === newTab) {
      return;
    }

    oldTab?.removeAttribute('aria-selected');
    newTab.setAttribute('aria-selected', 'true');

    const slider = tabsContainer.parentElement;

    if (slider) {
      const elemPosition = newTab.offsetLeft;
      const pos = slider.offsetLeft - elemPosition;

      if (slider.scrollLeft > elemPosition - newTab.offsetWidth) {
        slider.scrollTo({
          left: Math.max(0, Math.abs(pos) - slider.offsetWidth / 2),
        });
      }

      if (slider.scrollLeft + slider.offsetWidth - newTab.offsetWidth * 2 < elemPosition) {
        slider.scrollTo({
          left: Math.abs(pos + slider.offsetWidth / 2),
        });
      }

      slider.classList.add('scroll-smooth');
    }

    const newTabWidth = newTab.offsetWidth / tabsContainer.offsetWidth;
    tabsContainer.style.setProperty('--_left', newTab.offsetLeft + 'px');
    tabsContainer.style.setProperty('--_width', String(newTabWidth));

    // @ts-ignore
    currentTabElementRef.current = newTab;
    callback?.();
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    moveTo(event.currentTarget);
  };

  const moveToSelectedTab = useCallback(() => {
    const tabsContainer = tabListRef.current;

    if (!tabsContainer) {
      return;
    }

    if (disabled) {
      tabsContainer.style.setProperty('--_width', '0');
      return;
    }

    const tabElement = (tabsContainer.querySelector(`[aria-selected="true"]`) ||
      tabsContainer.firstElementChild) as HTMLButtonElement;

    if (tabElement) {
      yieldToMainThread().then(() => moveTo(tabElement));
    }
  }, [disabled, moveTo]);

  useEffect(() => {
    moveToSelectedTab();
  }, [moveToSelectedTab]);

  return { tabListRef, handleClick, moveTo, moveToSelectedTab, currentTabElementRef } as const;
};

export type NavBarRef = {
  moveTo: (elem: HTMLElement) => void;
  moveToSelectedTab: () => void;
  getCurrentTabElement: () => HTMLButtonElement | null;
  getTabsContainer: () => HTMLDivElement | null;
};

type Props = {
  children:
    | React.ReactNode
    | ((props: { handleClick: ReturnType<typeof useNavBar>['handleClick'] }) => React.ReactNode);
  className?: string;
  disabled?: boolean;
  innerRef: React.Ref<NavBarRef>;
};

const NavBar = ({ children, className, disabled, innerRef }: Props) => {
  const { tabListRef, handleClick, moveTo, moveToSelectedTab, currentTabElementRef } = useNavBar(disabled);

  useImperativeHandle(innerRef, () => ({
    moveTo,
    moveToSelectedTab,
    getCurrentTabElement: () => currentTabElementRef.current,
    getTabsContainer: () => tabListRef.current,
  }));

  return (
    <div ref={tabListRef} className={clx(style.tablist, className)} role='tablist'>
      {typeof children == 'function' ? children({ handleClick }) : children}
    </div>
  );
};

export default NavBar;
