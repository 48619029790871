'use client';

import AISearch_Bar_Form from './AiSearch_Bar_Form';
import useSearchBar from './useSearchBar';

const AiSearch_Bar = ({ className, collapsed = false }: { className?: string; collapsed?: boolean }) => {
  const { inputRef, formRef, handleSearch, placeholder, handleSubmit, toggleDisplay, search, submit, isOpen, onFocus } =
    useSearchBar();

  return (
    <AISearch_Bar_Form
      formRef={formRef}
      handleSubmit={handleSubmit}
      submit={submit}
      className={className}
      inputRef={inputRef}
      placeholder={placeholder}
      onFocus={onFocus}
      handleSearch={handleSearch}
      toggleDisplay={toggleDisplay}
      defaultValue={search || undefined}
      isOpen={isOpen}
      collapsed={collapsed}
    />
  );
};

export default AiSearch_Bar;
