import Button from '@wander/ui/src/components/Button/Button';
import { useDialog } from '@wander/ui/src/components/Dialog/DialogProvider';
import clx from '@wander/ui/src/utils/clx';

import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useSearch } from '../SearchProvider/SearchProvider_Context';

const Filters_Mobile_ButtonsBar = () => {
  const [, closeDialog] = useDialog();
  const { isCalendarOpen, isLocationOpen, isGuestOpen, toggleLocationFilter, toggleGuestFilter } = useSearch();
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const { replace } = useRouter();
  const isFilterOpen = isCalendarOpen || isLocationOpen || isGuestOpen;

  const handleClear = () => {
    let params = new URLSearchParams(searchParams);
    if (isCalendarOpen) {
      params.delete('checkIn');
      params.delete('checkOut');
    }

    if (isLocationOpen) {
      params.delete('states');
    }

    if (isGuestOpen) {
      params.delete('adults');
      params.delete('children');
      params.delete('infants');
      params.delete('pets');
    }

    if (!isFilterOpen) {
      params = new URLSearchParams();
    }

    replace(`${pathname}?${params}`);
  };

  const handleSubmit = () => {
    if (isCalendarOpen) {
      toggleLocationFilter();
      return;
    }

    if (isLocationOpen) {
      toggleGuestFilter();
      return;
    }

    closeDialog();
  };

  return (
    <div
      className={clx(
        'fixed bottom-0 left-0  flex h-[88px] w-full items-center justify-between gap-3 border-t border-t-w-6003 bg-1-black p-6 transition-transform'
      )}
    >
      <Button className='btn-invert w-full' onClick={handleClear}>
        {isFilterOpen ? 'Clear' : 'Clear filters'}
      </Button>
      <Button className='w-full' onClick={handleSubmit}>
        {isGuestOpen || !isFilterOpen ? 'Search' : 'Continue'}
      </Button>
    </div>
  );
};

export default Filters_Mobile_ButtonsBar;
