import clx from '@wander/ui/src/utils/clx';

import ButtonClear from './ButtonClear';

type Props = {
  value: string;
  onClickClear: () => void;
  className?: string;
};

const FilterValue = ({ className, value, onClickClear }: Props) => (
  <div className={clx('relative flex items-center justify-center gap-2', className)}>
    {value}
    <ButtonClear onClick={onClickClear} />
  </div>
);

export default FilterValue;
