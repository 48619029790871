'use client';

import Filters_Mobile_Details from '../Filters_Mobile_Details';
import Filters_Guest_Form from './Filters_Guest_Form';
import useGuestFilter from './useGuestFilter';

const Filters_Guest_Mobile = () => {
  const { isGuestOpen, toggleGuestFilter, handleGuestChange, guests, adults, children, infants, pets } =
    useGuestFilter();

  return (
    <Filters_Mobile_Details
      isOpen={isGuestOpen}
      onClick={toggleGuestFilter}
      label='Who'
      icon='people'
      value={guests ? String(guests) : 'Add guests'}
    >
      <Filters_Guest_Form
        onChange={handleGuestChange}
        adults={adults}
        // biome-ignore lint/correctness/noChildrenProp: it is children filter
        children={children}
        infants={infants}
        pets={pets}
      />
    </Filters_Mobile_Details>
  );
};

export default Filters_Guest_Mobile;
