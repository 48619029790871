type Event<T> = {
  target: T;
};
const onScrollStop =
  <T>(callback: (event: Event<T>) => void) =>
  (event: Event<T>) => {
    // @ts-ignore
    clearTimeout(event.target.isScrolling);
    // @ts-ignore
    event.target.isScrolling = setTimeout(() => callback(event), 50);
  };

export default onScrollStop;
