import Icon from '@wander/ui/src/components/Icon';
import clx from '@wander/ui/src/utils/clx';

const ButtonClear = ({ className, onClick, ...props }: React.HTMLProps<HTMLButtonElement>) => (
  <button
    {...props}
    onClickCapture={(e) => {
      e.stopPropagation();
      onClick?.(e);
    }}
    type='button'
    className={clx(
      'hidden h-4 w-4 place-items-center rounded-full bg-5-v-light-grey/20 hover:bg-white/20 focus:outline-none xl:grid',
      className
    )}
    aria-label='clear filter'
  >
    <Icon name='close' className='top-0 h-4 w-4 text-4-light-grey' />
  </button>
);

export default ButtonClear;
