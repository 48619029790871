'use client';

import { useCallback } from 'react';

import SearchButton from '../SearchButton';
import { useSearch } from '../SearchProvider/SearchProvider_Context';

const FilterSearchButton = ({ collapsed }: { collapsed?: boolean }) => {
  const { isCalendarOpen, isGuestOpen, isLocationOpen, toggleCalendarFilter, closeFilters } = useSearch();
  const isFilterOpen = isCalendarOpen || isLocationOpen || isGuestOpen;

  const handleClick = useCallback(() => {
    if (isFilterOpen) {
      closeFilters();
    } else {
      toggleCalendarFilter();
    }
  }, [isFilterOpen, toggleCalendarFilter]);

  return (
    <SearchButton
      aria-label='Search'
      className={isFilterOpen ? 'text-1-black bg-white hover:!bg-white' : ''}
      collapsed={collapsed}
      onClick={handleClick}
    />
  );
};

export default FilterSearchButton;
