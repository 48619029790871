import { useCallback, useRef, useState } from 'react';

import { persistLocalSearch } from '@/features/properties/hooks/useLatestAiSearch';
import { useClickOutside } from '@mantine/hooks';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useSearchDisplay } from '../../SearchDisplayProvider';
import { useSearch } from '../../SearchProvider/SearchProvider_Context';
import { useAiSearchExample } from '../AiSearch_Example/AiSearch_ExampleContext';

const useSearchBar = () => {
  const [placeholder] = useAiSearchExample();
  const { toggleDisplay } = useSearchDisplay();
  const [isOpen, setIsOpen] = useState(false);

  const { basePathname, search } = useSearch();

  const inputRef = useRef<HTMLInputElement>(null);
  const formRef = useClickOutside(() => {
    setIsOpen(false);
  });

  const pathname = usePathname();
  const { replace } = useRouter();
  const searchParams = useSearchParams();
  const currentSearch = searchParams.get('search')?.toString() || '';

  const handleSearch = useCallback(
    async (nextSearch: string) => {
      if (pathname !== basePathname || (inputRef.current && nextSearch != currentSearch)) {
        inputRef.current.value = nextSearch;
        search(`?search=${encodeURIComponent(nextSearch)}`);
        persistLocalSearch(nextSearch);
        setIsOpen(false);
      }
    },
    [currentSearch, pathname, replace]
  );

  const handleSubmit = useCallback(
    async (form: FormData) => {
      handleSearch(form.get('search') as string);
    },
    [handleSearch]
  );

  const onFocus = useCallback(() => {
    setIsOpen(true);
  }, []);

  const forceSubmit = useCallback(() => {
    inputRef.current?.form?.requestSubmit();
  }, []);

  return {
    inputRef,
    formRef,
    placeholder,
    handleSearch,
    handleSubmit,
    toggleDisplay,
    search: currentSearch,
    submit: forceSubmit,
    isOpen,
    onFocus,
  };
};

export default useSearchBar;
