'use client';

import Filters_Mobile_Details from '../Filters_Mobile_Details';
import Filters_Location_Form from './Filters_Location_Form';
import getFilterValue from './getFilterValue';
import useLocationFilter from './useLocationFilter';

const Filters_Location_Mobile = () => {
  const { isLocationOpen, toggleLocationFilter, toggleLocation, states } = useLocationFilter();

  return (
    <Filters_Mobile_Details
      isOpen={isLocationOpen}
      onClick={toggleLocationFilter}
      label='Where'
      icon='nav-pin'
      value={states.length ? getFilterValue(states) : 'Add location'}
    >
      <Filters_Location_Form onClickLocation={toggleLocation} values={states} />
    </Filters_Mobile_Details>
  );
};

export default Filters_Location_Mobile;
