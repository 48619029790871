'use client';

import Icon from '@wander/ui/src/components/Icon';
import clx from '@wander/ui/src/utils/clx';

import AiSearch_Input from '../AiSearch_Input';
import AiSearch_SwitchDisplay from '../AiSearch_SwitchDisplay';
import AiSearch_SuggestPan from './AiSearch_SuggestPan';

type Props = {
  formRef: React.RefObject<HTMLFormElement>;
  handleSubmit: (form: FormData) => Promise<void>;
  className?: string;
  inputRef: React.RefObject<HTMLInputElement>;
  placeholder: string;
  onFocus: () => void;
  onClear?: () => void;
  handleSearch: (nextSearch: string) => Promise<void>;
  toggleDisplay?: () => void;
  defaultValue?: string;
  isOpen: boolean;
  autoFocus?: boolean;
};

const AiSearch_Bar_Mobile_Form = ({
  formRef,
  handleSubmit,
  className,
  inputRef,
  placeholder,
  onFocus,
  handleSearch,
  toggleDisplay,
  defaultValue,
  onClear,
  isOpen,
  autoFocus = true,
}: Props) => {
  return (
    <form
      ref={formRef}
      action={handleSubmit}
      className={clx(
        'group relative flex h-12 w-full max-w-[601px] items-center rounded-lg border border-w-8002 p-2 px-3 transition-all',
        className
      )}
      style={{
        background: 'linear-gradient(180deg, #0F0F0F 0%, #1A1A1A 100%), #141414',
      }}
    >
      <Icon name='magnifying-glass' className='top-0 h-7 w-7 text-w-4004' />
      <AiSearch_Input
        inputRef={inputRef}
        placeholder={placeholder}
        className='truncate border-none focus:placeholder-transparent'
        autoComplete='off'
        defaultValue={defaultValue}
        // @ts-ignore
        enterKeyHint='search'
        onClear={onClear}
        onFocus={onFocus}
        autoFocus={autoFocus}
      />
      {toggleDisplay ? <AiSearch_SwitchDisplay className='hidden group-has-[input:placeholder-shown]:flex' /> : null}
      {isOpen ? <AiSearch_SuggestPan handleOnClickSearch={handleSearch} toggleDisplay={toggleDisplay} /> : null}
    </form>
  );
};

export default AiSearch_Bar_Mobile_Form;
