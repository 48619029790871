'use client';

import { useCallback, useEffect, useRef } from 'react';

import clx from '../../utils/clx';
import onScrollStop from '../../utils/onScrollStop';
import Icon from '../Icon';
import './style.css';
import { IconProps } from '../Icon/Icon';

const handleShowHideArrows = (event: React.UIEvent<HTMLDivElement>) => {
  const elem = event.target as HTMLDivElement;
  const wrapper = elem.parentElement;

  if (!wrapper) {
    return;
  }

  if (elem.scrollLeft > 100) {
    wrapper.firstElementChild?.classList.add('!opacity-100');
  } else {
    wrapper.firstElementChild?.classList.remove('!opacity-100');
  }

  if (elem.scrollLeft > elem.scrollWidth - elem.clientWidth - 100) {
    wrapper.lastElementChild?.classList.remove('!opacity-100');
  } else {
    wrapper.lastElementChild?.classList.add('!opacity-100');
  }
};

const handleSlideChange = (onChange: (index: number, total: number) => void) =>
  onScrollStop((event) => {
    const slider = event.target as HTMLDivElement;
    if (!slider) {
      return;
    }

    const rect = slider.getBoundingClientRect();
    let elem = document.elementFromPoint(rect.x + rect.width / 2, rect.y + 10);

    if (!elem) {
      return;
    }

    try {
      while (elem.parentElement !== slider) {
        elem = elem?.parentElement as HTMLElement;
      }
    } catch {
      return;
    }

    const slides = Array.from(slider.children);
    const index = slides.findIndex((child) => child === elem);

    if (index !== -1) {
      onChange(index, slides.length);
    }
  });

// million-ignore
const Carousel = ({
  children,
  className,
  buttonClassName,
  sliderClassName,
  step,
  noShadow,
  arrowNav = false,
  startAt = 0,
  onChange,
  IconComponent = Icon,
}: React.PropsWithChildren<{
  className?: string;
  buttonClassName?: string;
  sliderClassName?: string;
  step?: number;
  noShadow?: boolean;
  arrowNav?: boolean;
  startAt?: number;
  onChange?: (index: number, total: number) => void;
  IconComponent?: React.ComponentType<IconProps>;
}>) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const currentSlideRef = useRef(startAt);

  useEffect(() => {
    if (!sliderRef.current) {
      return;
    }

    const sliderElem = sliderRef.current;
    const nextButtonElement = sliderRef.current?.nextElementSibling;
    const parentElement = sliderRef.current?.parentElement;

    if (!nextButtonElement || !parentElement) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      if (sliderElem.scrollWidth > sliderElem.clientWidth + 100) {
        nextButtonElement.classList.add('!opacity-100');
        parentElement.classList.add('md:scroll-shadow-left', 'md:scroll-shadow-right');
      } else {
        nextButtonElement.classList.remove('!opacity-100');
        parentElement.classList.remove('md:scroll-shadow-left', 'md:scroll-shadow-right');
      }
    });

    resizeObserver.observe(sliderRef.current);

    return () => resizeObserver.disconnect();
  }, []);

  const goToPrev = useCallback(
    () => sliderRef.current?.scrollBy({ left: -(step || sliderRef.current.clientWidth), behavior: 'smooth' }),
    [step]
  );

  const goToNext = useCallback(
    () => sliderRef.current?.scrollBy({ left: step || sliderRef.current.clientWidth, behavior: 'smooth' }),
    [step]
  );

  useEffect(() => {
    if (!arrowNav) {
      return;
    }

    const handler = (event: KeyboardEvent) => {
      if (event.key === 'ArrowRight') {
        event.preventDefault();
        goToNext();
      }

      if (event.key === 'ArrowLeft') {
        event.preventDefault();
        goToPrev();
      }
    };

    window.addEventListener('keydown', handler);
    return () => window.removeEventListener('keydown', handler);
  }, [arrowNav, goToPrev, goToNext]);

  useEffect(() => {
    const slider = sliderRef.current;
    if (!slider || startAt === undefined) {
      return;
    }
    const elem = slider.children.item(startAt) as HTMLElement;
    const pos = Math.abs(slider.offsetLeft - elem.offsetLeft);
    slider.scrollTo(pos, 0);
    currentSlideRef.current = startAt;
  }, [startAt]);

  const handleChange = useCallback(
    (index: number, total: number) => {
      if (currentSlideRef.current !== index) {
        currentSlideRef.current = index;
        onChange?.(index + 1, total);
      }
    },
    [onChange]
  );

  return (
    <div className={clx('relative block w-full p-px', className)}>
      <label data-role='prev' className={clx('scroll-shadow-left opacity-0 max-md:!hidden', noShadow && '!bg-none')}>
        <button
          aria-label='show previous'
          className={clx(
            'btn btn-rounded btn-invert absolute left-0 top-1/2 z-50 h-8 w-8 -translate-y-1/2',
            buttonClassName
          )}
          onClick={goToPrev}
        >
          <IconComponent name='chevron-left' className='top-0' />
        </button>
      </label>
      <div
        data-role='slider'
        ref={sliderRef}
        className={clx(
          'scrollbar-hide z-0 flex h-full w-full snap-x snap-mandatory items-stretch gap-3 overflow-x-auto',
          currentSlideRef.current != startAt && 'scroll-smooth',
          sliderClassName
        )}
        onScroll={handleShowHideArrows}
        onScrollCapture={handleSlideChange(handleChange)}
      >
        {children}
      </div>
      <label data-role='next' className={clx('scroll-shadow-right opacity-0 max-md:!hidden', noShadow && '!bg-none')}>
        <button
          aria-label='show next'
          className={clx(
            'btn btn-rounded btn-invert absolute right-0 top-1/2 z-50 h-8 w-8 -translate-y-1/2',
            buttonClassName
          )}
          onClick={goToNext}
        >
          <IconComponent name='chevron-right' className='top-0' />
        </button>
      </label>
    </div>
  );
};

export default Carousel;
