import { useState } from 'react';

import Input from '@wander/ui/src/components/Input/Input';

import getFilterLocationStates from '@/features/properties/actions/getFilterLocationStates';
import useSWRImmutable from 'swr/immutable';

const FallbackStates = () =>
  Array.from({ length: 10 }).map((_, index) => (
    <div key={index} className='btn btn-invert animate-pulse !bg-1-black/10' />
  ));

type Props = {
  onClickLocation: (value: string) => void;
  values: string[];
};

const Filters_Location_Form = ({ onClickLocation, values }: Props) => {
  const { data: availableStates } = useSWRImmutable('states', getFilterLocationStates);

  const [term, setTerm] = useState('');
  return (
    <>
      <div className='w-full border-b border-b-w-6003 pb-6'>
        <div className='font-medium max-lg:hidden'>Where to?</div>
        <Input
          placeholder='Search by states...'
          className='mt-3'
          value={term}
          onChange={(e) => setTerm(e.currentTarget.value)}
        />
      </div>

      <div className='scrollbar-hide grid w-full grid-cols-2 gap-3 overflow-auto overscroll-none pt-6'>
        {availableStates
          ?.filter((state) => state.toLowerCase().startsWith(term.toLowerCase()))
          .map((state) => (
            <label
              key={state}
              className='btn btn-invert relative flex gap-2 overflow-hidden has-[input:checked]:!border-white has-[input:not(:checked)]:!font-base has-[input:not(:checked)]:!text-w-3005'
            >
              <input
                type='checkbox'
                value={state}
                onChange={(event) => onClickLocation(event.target.value)}
                checked={values.includes(state)}
                className='absolute -left-full'
              />
              {state}
            </label>
          )) || <FallbackStates />}
      </div>
    </>
  );
};

export default Filters_Location_Form;
