import Icon from '@wander/ui/src/components/Icon';

type Props = {
  onClick: () => void;
  isOpen: boolean;
  label: string;
  value: string;
  icon: string;
};

const Filters_Mobile_Details = ({ onClick, isOpen, label, value, icon, children }: React.PropsWithChildren<Props>) => (
  <div className='rounded-xl border border-w-6003 bg-w-8002'>
    <button onClick={onClick} className='flex w-full items-center justify-between px-4 py-[28px]'>
      <div>{label}</div>
      <div className='flex items-center'>
        <Icon name={icon} className='top-0 mr-3' />

        {value}
      </div>
    </button>

    {isOpen && (
      <div className='grid w-full place-items-center px-4 pb-[28px] [&_.rdp]:-ml-5 [&_.rdp]:-mt-10 [&_.rdp]:scale-[0.8]'>
        {children}
      </div>
    )}
  </div>
);

export default Filters_Mobile_Details;
