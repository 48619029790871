'use client';

import { createContext, useContext } from 'react';

import useInMemoryState from '@wander/ui/src/hooks/useInMemoryState';

type Display = 'ai' | 'classic';

const Context = createContext<{ display: Display; toggleDisplay: () => void } | undefined>(undefined);

type Props = {
  defaultValue?: Display;
  children: React.ReactNode;
};

const SearchDisplayProvider = ({ defaultValue = 'ai', children }: Props) => {
  const [display = defaultValue, setDisplay] = useInMemoryState<Display>('search_display', defaultValue);

  const toggleDisplay = () => {
    setDisplay((display) => (display == 'ai' ? 'classic' : 'ai'));
  };
  return <Context.Provider value={{ display, toggleDisplay }}>{children}</Context.Provider>;
};

export default SearchDisplayProvider;

export const useSearchDisplay = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error('useSearchDisplay must be used within a SearchDisplay');
  }

  return context;
};
