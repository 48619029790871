'use client';

import Icon from '@wander/ui/src/components/Icon';
import clx from '@wander/ui/src/utils/clx';

type Props = {
  onClick?: () => void;
  className?: string;
  collapsed?: boolean;
};

const SearchButton = ({ onClick, className, collapsed }: Props) => {
  return (
    <button
      type='button'
      aria-label='Search'
      className={clx(
        'absolute right-[11px] z-50 grid aspect-square w-8 place-items-center rounded-md border border-1-black/20 transition-all',
        !collapsed
          ? 'group-[&[data-fixed="true"]]/body:!right-[6px] group-[&[data-fixed="true"]]/body:!w-6'
          : '!right-[6px] !w-6',
        'bg-w-8002 from-[#292929] to-[#2E2E2E] text-w-3005 hover:!bg-gradient-to-b',
        className
      )}
      onClick={onClick}
    >
      <Icon
        name='magnifying-glass'
        className={clx('top-0 w-5', !collapsed ? 'group-[&[data-fixed="true"]]/body:!w-4' : '!w-4')}
      />
    </button>
  );
};

export default SearchButton;
