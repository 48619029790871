import clx from '@wander/ui/src/utils/clx';

type Props = {
  label: string;
  fixedLabel: string;
  collapsed?: boolean;
};

const FilterPlaceholder = ({ label, fixedLabel, collapsed }: Props) => (
  <>
    {!collapsed && (
      <div className='visible group-[&[data-fixed="true"]]/body:invisible group-[&[data-fixed="true"]]/body:h-0'>
        {label}
      </div>
    )}
    <div
      className={clx(
        'invisible h-0',
        !collapsed
          ? 'group-[&[data-fixed="true"]]/body:visible group-[&[data-fixed="true"]]/body:h-auto'
          : 'visible h-auto'
      )}
    >
      {fixedLabel}
    </div>
  </>
);

export default FilterPlaceholder;
