import { type ReadonlyURLSearchParams, useSearchParams } from 'next/navigation';
import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useSearch } from '../../SearchProvider/SearchProvider_Context';

const parse = (value: string | number) => {
  const parsed = Number(value);
  return isNaN(parsed) ? 0 : parsed;
};

const getGuestFilter = (searchParams: ReadonlyURLSearchParams) => {
  return {
    adults: parse(searchParams.get('adults') || 0),
    children: parse(searchParams.get('children') || 0),
    infants: parse(searchParams.get('infants') || 0),
    pets: parse(searchParams.get('pets') || 0),
  };
};

const useGuestFilter = () => {
  const { isGuestOpen, toggleGuestFilter, search } = useSearch();

  const searchParams = useSearchParams();

  const [guestFilter, setGuestFilter] = useState(getGuestFilter(searchParams));

  const resetFilters = () => {
    setGuestFilter({ adults: 0, children: 0, infants: 0, pets: 0 });

    const params = new URLSearchParams(searchParams);
    params.delete('adults');
    params.delete('children');
    params.delete('infants');
    params.delete('pets');

    search(`?${params}`);
  };

  const handleSubmit = useDebouncedCallback(() => {
    const params = new URLSearchParams(searchParams);
    const { adults, children, infants, pets } = guestFilter;

    if (adults) {
      params.set('adults', String(adults));
    } else {
      params.delete('adults');
    }

    if (children) {
      params.set('children', String(children));
    } else {
      params.delete('children');
    }

    if (infants) {
      params.set('infants', String(infants));
    } else {
      params.delete('infants');
    }

    if (pets) {
      params.set('pets', String(pets));
    } else {
      params.delete('pets');
    }

    search(`?${params}`);
  }, 500);

  const handleGuestChange = (key: string) => (value: number) => {
    setGuestFilter({ ...guestFilter, [key]: value });
    handleSubmit();
  };

  const { adults, children, infants, pets } = guestFilter;
  const guests = adults + children + infants;

  return {
    isGuestOpen,
    toggleGuestFilter,
    resetFilters,
    handleGuestChange,
    handleSubmit,
    guests,
    adults,
    children,
    infants,
    pets,
  };
};

export default useGuestFilter;
