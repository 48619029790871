import { createContext, useContext } from 'react';

type DropdownMenuContext = {
  isOpen: boolean;
  toggle: () => void;
  forceOpen: () => void;
  forceClose: () => void;
};

const Context = createContext<DropdownMenuContext | null>(null);

export const DropdownMenuProvider = ({
  children,
  value,
}: {
  value: DropdownMenuContext;
  children: React.ReactNode;
}) => <Context.Provider value={value}>{children}</Context.Provider>;

export const useDropdownMenuContext = () => {
  const ctx = useContext(Context);

  if (ctx === null) {
    throw new Error('useDropdownMenuContext must be used within a DropdownMenuProvider');
  }

  return ctx;
};
