'use client';

import DropDownMenu from '@/widget/DropDownMenu';
import ButtonFilter from '../ButtonFilter';
import ButtonsBar from '../ButtonsBar';
import FilterLabel from '../FilterLabel';
import FilterPlaceholder from '../FilterPlaceholder';
import FilterValue from '../FilterValue';
import Filters_Location_Form from './Filters_Location_Form';
import getFilterValue from './getFilterValue';
import useLocationFilter from './useLocationFilter';

type Props = {
  className?: string;
  collapsed?: boolean;
};

const Filters_Location = ({ className, collapsed }: Props) => {
  const { isLocationOpen, toggleLocationFilter, toggleLocation, states, resetFilters, handleSubmit } =
    useLocationFilter();

  return (
    <>
      <ButtonFilter icon='nav-pin' onClick={toggleLocationFilter} className={className} active={isLocationOpen}>
        {!collapsed && <FilterLabel>Where</FilterLabel>}
        {states.length ? (
          <FilterValue value={getFilterValue(states)} onClickClear={resetFilters} />
        ) : (
          <FilterPlaceholder label='Add location' fixedLabel='Wherever' collapsed={collapsed} />
        )}
      </ButtonFilter>
      <DropDownMenu
        open={isLocationOpen}
        onClose={toggleLocationFilter}
        className='fixed left-1/2 top-1/2 z-[51] mx-auto w-full max-w-[383px] -translate-x-1/2 -translate-y-1/2 !animate-overlayShow p-0 sm:absolute sm:top-full sm:translate-y-6'
      >
        <div className='grid grid-rows-[120px_348px_88px] [&>div]:!p-6'>
          <Filters_Location_Form onClickLocation={toggleLocation} values={states} />

          <div className='sticky bottom-4 flex h-[88px] w-full items-center justify-between rounded-b-xl border-t border-t-w-6003 bg-1-black p-6'>
            <ButtonsBar
              onClear={resetFilters}
              // @ts-ignore
              onSubmit={handleSubmit}
              submitLabel='Continue'
              disabledSubmit={states.length == 0}
            />
          </div>
        </div>
      </DropDownMenu>
    </>
  );
};

export default Filters_Location;
