'use client';

import { useEffect } from 'react';

import { useSearchParams } from 'next/navigation';

import { useDialog } from '@wander/ui/src/components/Dialog/DialogProvider';

type Props = {
  id: string;
};

const VideoDialog_WatchId = ({ id }: Props) => {
  const searchParams = useSearchParams();
  const [openDialog] = useDialog();

  useEffect(() => {
    if (searchParams?.has('watch')) {
      if (searchParams.get('watch') === id) {
        openDialog();
      }
    }
  }, [id, searchParams, openDialog]);

  return null;
};

export default VideoDialog_WatchId;
