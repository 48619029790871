import Button from '@wander/ui/src/components/Button/Button';

type Props = {
  clearLabel?: string;
  submitLabel?: string;
  onClear: () => void;
  onSubmit: () => void;
  disabledSubmit?: boolean;
};

const ButtonsBar = ({
  clearLabel = 'Clear',
  submitLabel = 'Search',
  onClear,
  onSubmit,
  disabledSubmit = false,
}: Props) => (
  <>
    <Button onClick={onClear} className='btn-md btn-invert mr-4'>
      {clearLabel}
    </Button>
    <Button onClick={onSubmit} className='btn-md' disabled={disabledSubmit}>
      {submitLabel}
    </Button>
  </>
);

export default ButtonsBar;
