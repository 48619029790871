'use client';

import DropDownMenu from '@/widget/DropDownMenu';
import { Suspense, lazy } from 'react';
import ButtonFilter from '../ButtonFilter';
import ButtonsBar from '../ButtonsBar';
import FilterLabel from '../FilterLabel';
import FilterPlaceholder from '../FilterPlaceholder';
import FilterValue from '../FilterValue';
import useCalendar from './useCalendar';

const Calendar = lazy(() => import('@/features/booking/components/Calendar'));

type Props = {
  className?: string;
  collapsed?: boolean;
};

const CalendarFilter = ({ className, collapsed }: Props) => {
  const {
    isCalendarOpen,
    toggleCalendarFilter,
    checkInDate,
    checkOutDate,
    dates,
    resetFilters,
    handleSubmit,
    onChange,
  } = useCalendar();

  return (
    <>
      <ButtonFilter icon='calendar' onClick={toggleCalendarFilter} className={className} active={isCalendarOpen}>
        {!collapsed && <FilterLabel>When</FilterLabel>}
        {dates ? (
          <FilterValue value={dates} onClickClear={resetFilters} />
        ) : (
          <FilterPlaceholder label='Add dates' fixedLabel='Whenever' collapsed={collapsed} />
        )}
      </ButtonFilter>
      <DropDownMenu
        open={isCalendarOpen}
        onClose={toggleCalendarFilter}
        className='fixed left-1/2 top-1/2 z-[51] mx-auto w-max -translate-x-1/2 -translate-y-1/2 !animate-overlayShow py-5 sm:absolute sm:top-full sm:translate-y-6'
      >
        <Suspense fallback={null}>
          <Calendar onChange={onChange} checkIn={checkInDate} checkOut={checkOutDate} />
        </Suspense>
        <div className='flex w-full items-center justify-between px-6'>
          <ButtonsBar
            onClear={resetFilters}
            // @ts-ignore
            onSubmit={handleSubmit}
            submitLabel='Continue'
            disabledSubmit={!checkInDate || !checkOutDate}
          />
        </div>
      </DropDownMenu>
    </>
  );
};

export default CalendarFilter;
