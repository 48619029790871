const startViewTransition = (callback: () => void) => {
  // @ts-ignore
  if (typeof document.startViewTransition == 'undefined') {
    setTimeout(callback);
    return;
  }

  // @ts-ignore
  document.startViewTransition(callback);
};

export default startViewTransition;
