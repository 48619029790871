'use client';

import AiSearch_Bar_Mobile_Form from './AiSearch_Bar_Mobile_Form';
import useSearchBar from './useSearchBar';

const AiSearch_Bar = ({ className, onClear }: { className?: string; onClear?: () => void }) => {
  const { inputRef, formRef, handleSearch, placeholder, handleSubmit, toggleDisplay, search, isOpen, onFocus } =
    useSearchBar();

  return (
    <AiSearch_Bar_Mobile_Form
      formRef={formRef}
      className={className}
      inputRef={inputRef}
      placeholder={placeholder}
      defaultValue={search || undefined}
      onClear={onClear}
      onFocus={onFocus}
      handleSubmit={handleSubmit}
      handleSearch={handleSearch}
      toggleDisplay={toggleDisplay}
      isOpen={isOpen}
    />
  );
};

export default AiSearch_Bar;
