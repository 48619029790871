'use client';

import { MediaPlayer, MediaProvider, Poster } from '@vidstack/react';
import clx from '../../utils/clx';

const CLOUDFLARE_STREAM_CUSTOMER_ID = 'qmayhafe7iottqnh';
const getVideoSrc = (videoId: string) =>
  `https://customer-${CLOUDFLARE_STREAM_CUSTOMER_ID}.cloudflarestream.com/${videoId}/manifest/video.m3u8`;
const getPosterSrc = (videoId: string) =>
  `https://customer-${CLOUDFLARE_STREAM_CUSTOMER_ID}.cloudflarestream.com/${videoId}/thumbnails/thumbnail.jpg?time=1s&height=768`;

const StreamingPlayer = ({
  videoId,
  ...props
}: Omit<React.ComponentProps<typeof MediaPlayer>, 'src' | 'children'> & {
  videoId: string;
}) => (
  <MediaPlayer src={getVideoSrc(videoId)} playsInline className='w-full h-full' {...props}>
    <MediaProvider className='w-full h-full' mediaProps={{ className: clx('w-full h-full', props.className) }} />
    <Poster src={getPosterSrc(videoId)} />
  </MediaPlayer>
);

export default StreamingPlayer;
