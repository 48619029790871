'use client';

import React, { useCallback, useImperativeHandle } from 'react';

import useToggle from '@wander/ui/src/hooks/useToggle';
import clx from '@wander/ui/src/utils/clx';

import { DropdownMenuProvider } from './context';

export type TriggerProps = { open: boolean; toggle: () => void };
export type CommandProps = { toggle: () => void; open: () => void; close: () => void };

type Props = React.PropsWithChildren<{
  innerRef?: React.ForwardedRef<CommandProps>;
  open?: boolean;
  onClose?: (() => void) | undefined;
  className?: string;
  trigger?: (props: TriggerProps) => React.ReactNode;
}>;

const DropdownMenu = ({ open = false, onClose, trigger, className, children, innerRef }: Props) => {
  const [isOpen, toggle, forceOpen, forceClose] = useToggle(open);

  useImperativeHandle(innerRef, () => ({ toggle, open: forceOpen, close: forceClose }), [
    toggle,
    forceOpen,
    forceClose,
  ]);

  const handleClose = useCallback(() => {
    trigger ? forceClose() : onClose?.();
  }, [trigger, onClose, forceClose]);

  return (
    <DropdownMenuProvider value={{ isOpen, toggle, forceOpen, forceClose }}>
      {trigger ? trigger({ open: isOpen, toggle }) : undefined}
      {((trigger && isOpen) || open) && (
        <>
          <div className='fixed inset-0 h-screen w-screen bg-transparent' onClick={handleClose} />
          <ul
            className={clx(
              'animate-slideUpAndFade rounded-xl border border-white/20 bg-black py-3 will-change-[opacity,transform]',
              className
            )}
          >
            {children}
          </ul>
        </>
      )}
    </DropdownMenuProvider>
  );
};

export default DropdownMenu;
