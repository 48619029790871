import Icon from '@wander/ui/src/components/Icon';
import clx from '@wander/ui/src/utils/clx';

const AiSearch_Badge = ({ className }: { className?: string }) => (
  <div
    className={clx(
      'flex w-fit flex-none select-none items-center gap-[6px] rounded-md bg-w-8002 px-[6px] py-1 text-sm',
      className
    )}
  >
    <Icon name='stars-light-sparkle' className='top-0 h-4 w-4 text-w-4004' />
    <span>WanderAI</span>
  </div>
);

export default AiSearch_Badge;
