import Icon from '@wander/ui/src/components/Icon';

const ButtonRound = (props: React.HTMLProps<HTMLButtonElement>) => (
  <button {...props} type='button' className='btn btn-invert btn-rounded w-9' />
);

type GuestsSelectorDropDownMenuItemProps = {
  label: string;
  subLabel?: React.ReactNode;
  value: number;
  onChange: (value: number) => void;
};

const GuestsSelectorDropDownMenuItem = ({ label, subLabel, value, onChange }: GuestsSelectorDropDownMenuItemProps) => {
  return (
    <div className='flex w-full justify-between p-3 text-white hover:bg-transparent'>
      <div className='flex flex-1 flex-col pr-4'>
        <span className='text-base font-medium'>{label}</span>
        {subLabel ? <span className='text-sm font-base text-white/80'>{subLabel}</span> : undefined}
      </div>
      <div className='row-start-1 flex w-[118px] items-center justify-between gap-2'>
        <ButtonRound onClick={() => onChange(value - 1 >= 0 ? value - 1 : 0)} disabled={value === 0}>
          <Icon name='minus' className='top-0 h-[10px] w-[10px]' />
        </ButtonRound>
        <span className='font-base'>{value || 'Any'}</span>
        <ButtonRound onClick={() => onChange(value + 1)}>
          <Icon name='plus' className='top-0 h-[10px] w-[10px]' />
        </ButtonRound>
      </div>
    </div>
  );
};

type Props = {
  onChange: (guestType: string) => (value: number) => void;
  adults: number;
  children: number;
  infants: number;
  pets: number;
};

const Filters_Guest_Form = ({ onChange, adults, children, infants, pets }: Props) => (
  <>
    <GuestsSelectorDropDownMenuItem onChange={onChange('adults')} label='Adults' subLabel='Age 13+' value={adults} />
    <GuestsSelectorDropDownMenuItem
      onChange={onChange('children')}
      label='Children'
      subLabel='Age 2-12'
      value={children}
    />
    <GuestsSelectorDropDownMenuItem onChange={onChange('infants')} label='Infants' subLabel='Under 2' value={infants} />
    <GuestsSelectorDropDownMenuItem onChange={onChange('pets')} label='Pets' value={pets} />
  </>
);

export default Filters_Guest_Form;
