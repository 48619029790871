import clx from '@wander/ui/src/utils/clx';

import SearchButton from '../../SearchButton';
import AiSearch_Badge from '../AiSearch_Badge';
import AiSearch_Input from '../AiSearch_Input';
import AiSearch_SuggestPan from './AiSearch_SuggestPan';

type Props = {
  formRef: React.RefObject<HTMLFormElement>;
  handleSubmit: (form: FormData) => Promise<void>;
  className?: string;
  inputRef: React.RefObject<HTMLInputElement>;
  placeholder: string;
  onFocus: () => void;
  handleSearch: (nextSearch: string) => Promise<void>;
  toggleDisplay?: () => void;
  defaultValue?: string;
  submit: () => void;
  isOpen: boolean;
  collapsed?: boolean;
};

const AISearch_Bar_Form = ({
  formRef,
  handleSubmit,
  className,
  inputRef,
  placeholder,
  onFocus,
  handleSearch,
  toggleDisplay,
  defaultValue,
  submit,
  isOpen = false,
  collapsed = false,
}: Props) => {
  return (
    <form
      ref={formRef}
      action={handleSubmit}
      className={clx(
        'group relative flex h-12 w-full max-w-[601px] items-center rounded-lg border border-w-8002 p-2 px-3 pr-12 transition-all',
        !collapsed
          ? 'group-[&[data-fixed="true"]]/body:h-9 group-[&[data-fixed="true"]]/body:max-w-[500px] group-[&[data-fixed="true"]]/body:py-0 group-[&[data-fixed="true"]]/body:pl-0 group-[&[data-fixed="true"]]/body:pr-8'
          : 'h-9 max-w-[500px] py-0 pl-0 pr-8',
        className
      )}
      style={{
        background: 'linear-gradient(180deg, #0F0F0F 0%, #1A1A1A 100%), #141414',
      }}
    >
      <AiSearch_Badge className={clx(!collapsed ? 'group-[&[data-fixed="true"]]/body:scale-90' : 'scale-90')} />
      <AiSearch_Input
        inputRef={inputRef}
        placeholder={placeholder}
        className='border-none text-sm focus:placeholder-transparent'
        autoComplete='off'
        defaultValue={defaultValue}
        onFocus={onFocus}
      />
      <SearchButton onClick={submit} collapsed={collapsed} />
      <AiSearch_SuggestPan
        className={isOpen ? '' : 'hidden'}
        handleOnClickSearch={handleSearch}
        toggleDisplay={toggleDisplay}
      />
    </form>
  );
};

export default AISearch_Bar_Form;
