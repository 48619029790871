import client from '@/services/fetcher/client';

const getFilterLocationStates = async () => {
  try {
    const data: Array<string> = await client.fetch('/guest-api/listings/states/').then((res) => res.json());

    return data.filter(Boolean);
  } catch {
    return undefined;
  }
};

export default getFilterLocationStates;
