'use client';

import { createContext, useContext } from 'react';

const Context = createContext<string[]>([]);

const AiSearch_ExampleContext = ({
  content,
  children,
}: React.PropsWithChildren<{
  content: string[];
}>) => {
  return <Context.Provider value={content}>{children}</Context.Provider>;
};

export default AiSearch_ExampleContext;

export const useAiSearchExample = () => useContext(Context);
