import { format } from 'date-fns';
import { usePathname, useSearchParams } from 'next/navigation';
import { useState } from 'react';
import { useSearch } from '../../SearchProvider/SearchProvider_Context';
import formatDate from './formatDate';

const useCalendar = () => {
  const { isCalendarOpen, toggleCalendarFilter, toggleLocationFilter, search } = useSearch();

  const pathname = usePathname();
  const searchParams = useSearchParams();
  const checkIn = searchParams.get('checkIn')?.toString();
  const checkOut = searchParams.get('checkOut')?.toString();

  const [checkInDate, setCheckInDate] = useState(() => (checkIn ? new Date(checkIn) : undefined));
  const [checkOutDate, setCheckOutDate] = useState(() => (checkOut ? new Date(checkOut) : undefined));

  const dates = formatDate(checkInDate, checkOutDate);

  const handleSubmit = () => {
    if (checkInDate && checkOutDate) {
      toggleLocationFilter();
    }
  };

  const resetFilters = () => {
    const params = new URLSearchParams(searchParams);
    params.delete('checkIn');
    params.delete('checkOut');
    setCheckInDate(undefined);
    setCheckOutDate(undefined);
    search(`${pathname}?${params}`);
  };

  const setCheckInCheckOut = ({
    checkIn,
    checkOut,
  }: {
    checkIn: Date;
    checkOut?: Date;
  }) => {
    if (checkIn && !checkOut) {
      setCheckInDate(checkIn);
      setCheckOutDate(undefined);
    } else {
      setCheckInDate(checkIn);
      setCheckOutDate(checkOut);
      search(`${pathname}?checkIn=${format(checkIn, 'yyyy-MM-dd')}&checkOut=${format(checkOut, 'yyyy-MM-dd')}`);
    }
  };

  return {
    isCalendarOpen,
    toggleCalendarFilter,
    checkInDate,
    checkOutDate,
    dates,
    resetFilters,
    handleSubmit,
    onChange: setCheckInCheckOut,
  };
};

export default useCalendar;
