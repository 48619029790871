import Icon from '@wander/ui/src/components/Icon';

const AiSearch_DropdownList = <T,>({
  title,
  items,
  onClick,
  getKey = (item) => String(item),
  render = (item, onClick) => (
    <>
      <button
        type='button'
        className='btn-inline min-w-0 flex-1 text-left'
        onClick={onClick ? () => onClick(item) : undefined}
      >
        <div className='truncate'>{String(item)}</div>
      </button>
    </>
  ),
}: {
  title?: string;
  items: T[];
  onClick?: (item: T) => void;
  getKey?: (item: T) => string;
  render?: (item: T, onClick?: (item: T) => void) => React.ReactNode;
}) => (
  <div className='overflow-hidden py-[6px]'>
    {title ? <h3 className='h-8 p-3 text-eyebrow-xs text-w-4004'>{title}</h3> : null}
    <ul className='text-sm text-w-3005'>
      {items.map((item) => (
        <li
          className='flex h-8 w-full items-center justify-between px-3 focus-within:bg-white/10 hover:bg-white/10'
          key={getKey(item)}
        >
          {render(item, onClick)}
          <Icon name='arrow-top-right' className='top-0 h-5 w-5 flex-none' />
        </li>
      ))}
    </ul>
  </div>
);

export default AiSearch_DropdownList;
