import Icon from '@wander/ui/src/components/Icon';
import clx from '@wander/ui/src/utils/clx';

import { useSearchDisplay } from '../SearchDisplayProvider';

const AiSearch_SwitchDisplay = ({ className }: { className?: string }) => {
  const { display, toggleDisplay } = useSearchDisplay();

  return (
    <button type='button' className={clx('flex gap-1', className)} onClick={toggleDisplay}>
      <div className={clx('grid h-7 w-7 place-items-center rounded-md', display == 'ai' && 'bg-w-6003')}>
        <Icon name='ai' className='top-0 h-4 w-4' />
      </div>
      <div className={clx('grid h-7 w-7 place-items-center rounded-md', display == 'classic' && 'bg-w-6003')}>
        <Icon name='checklist-2' className='top-0 h-4 w-4' />
      </div>
    </button>
  );
};

export default AiSearch_SwitchDisplay;
