'use client';

import { useCallback } from 'react';

import useToggle from '@wander/ui/src/hooks/useToggle';
import { useRouter } from 'next/navigation';
import { flushSync } from 'react-dom';

import startViewTransition from '@/utils/startViewTransition';
import SearchProvider_Context from './SearchProvider_Context';

const SearchProvider = ({
  children,
  basePathname = '/',
}: React.PropsWithChildren<{
  basePathname?: string;
}>) => {
  const [isCalendarOpen, toggleCalendarFilter, _openCalendarFilter, closeCalendarFilter] = useToggle();
  const [isGuestOpen, toggleGuestFilter, _openGuestFilter, closeGuestFilter] = useToggle();
  const [isLocationOpen, toggleLocationFilter, _openLocationFilter, closeLocationFilter] = useToggle();

  const { replace, prefetch: doPrefetch, refresh } = useRouter();

  const loadMore = useCallback(() => {}, []);

  const search = useCallback(
    (searchParams = '', prefetch = false) => {
      if (searchParams === '') {
        replace(`${basePathname}`);
        refresh();
        return;
      }

      if (prefetch) {
        doPrefetch(`${basePathname}${searchParams}`);
      } else {
        startViewTransition(() => {
          flushSync(() => {
            replace(`${basePathname}${searchParams}`);
          });
        });
      }
    },
    [basePathname, replace, doPrefetch, refresh]
  );

  const closeFilters = useCallback(() => {
    closeCalendarFilter();
    closeGuestFilter();
    closeLocationFilter();
  }, [closeCalendarFilter, closeGuestFilter, closeLocationFilter]);

  return (
    <SearchProvider_Context
      value={{
        basePathname,
        isCalendarOpen,
        toggleCalendarFilter: () => {
          closeGuestFilter();
          closeLocationFilter();
          toggleCalendarFilter();
        },
        isGuestOpen,
        toggleGuestFilter: () => {
          closeCalendarFilter();
          closeLocationFilter();
          toggleGuestFilter();
        },
        isLocationOpen,
        toggleLocationFilter: () => {
          closeGuestFilter();
          closeCalendarFilter();
          toggleLocationFilter();
        },
        closeFilters,
        loadMore,
        search,
      }}
    >
      {children}
    </SearchProvider_Context>
  );
};

export default SearchProvider;
