'use client';

import Icon from '@wander/ui/src/components/Icon';
import clx from '@wander/ui/src/utils/clx';

import { useSearchDisplay } from './SearchDisplayProvider';

const SearchDisplaySwitch = ({ className }: { className?: string }) => {
  const { toggleDisplay } = useSearchDisplay();

  return (
    <button
      type='button'
      onClick={toggleDisplay}
      className={clx(
        'flex h-8 w-full items-center justify-center gap-[6px] rounded-b-lg border border-t-0 border-w-8002',
        'transition-[height] duration-150 group-[&[data-fixed="true"]]/body:!h-9',
        'bg-gradient-to-b from-[#141414] to-[#1A1A1A]',
        'hover:!from-[#292929] hover:!to-[#292929]',
        className
      )}
    >
      <Icon name='reload' className='top-0 h-5 w-5 text-w-4004' />
      <div className='text-sm text-w-4004'>
        Search using <span className='text-w-3005'>WanderAI</span>
      </div>
    </button>
  );
};

export default SearchDisplaySwitch;
