'use client';

import { Suspense, useState } from 'react';

import Dialog from '@wander/ui/src/components/Dialog';
import Icon from '@wander/ui/src/components/Icon';
import clx from '@wander/ui/src/utils/clx';

import { useSearchParams } from 'next/navigation';
import AiSearch_Bar_Mobile from '../AiSearch/AiSearch_Bar/AiSearch_Bar_Mobile';
import AiSearch_SwitchDisplay from '../AiSearch/AiSearch_SwitchDisplay';
import SearchDisplayAi from '../SearchDisplayAi';
import { useSearchDisplay } from '../SearchDisplayProvider';
import Filters_Calendar_Mobile from './Filters_Calendar/Filters_Calendar_Mobile';
import formatDate from './Filters_Calendar/formatDate';
import Filters_Guest_Mobile from './Filters_Guest/Filters_Guest_Mobile';
import Filters_Location_Mobile from './Filters_Location/Filters_Location_Mobile';
import Filters_Mobile_ButtonsBar from './Filters_Mobile_ButtonsBar';

type FilterProps = {
  icon: string;
  value: string;
};

const Filter = ({ icon, value }: FilterProps) => (
  <div className='flex items-center gap-[6px] truncate text-w-4004'>
    <Icon name={icon} className='top-0 h-[14px] w-[14px]' />
    <div className='grid text-xs group-hover:text-white'>{value}</div>
  </div>
);

const Classic = () => {
  const searchParams = useSearchParams();

  const checkIn = searchParams.get('checkIn');
  const checkOut = searchParams.get('checkOut');
  const states = searchParams.getAll('states');
  const adults = Number(searchParams.get('adults'));
  const children = Number(searchParams.get('children'));
  const infants = Number(searchParams.get('infants'));

  const guests = adults + children + infants;

  const checkInDate = checkIn ? new Date(checkIn) : undefined;
  const checkOutDate = checkOut ? new Date(checkOut) : undefined;
  const dates = formatDate(checkInDate, checkOutDate);

  return (
    <>
      <Filter icon='calendar' value={dates ? dates : 'Whenever'} />
      <Filter icon='nav-pin' value={states?.length ? states[0] : 'Wherever'} />
      <Filter icon='people' value={guests ? String(guests) : 'Whoever'} />
    </>
  );
};

const Ai = () => {
  const searchParams = useSearchParams();
  const search = searchParams.get('search');

  return <div className='truncate text-sm text-w-4004'>{search ? search : `Try “california, 4 people, ocean”`}</div>;
};

type Props = {
  className?: string;
};

const Filters_Mobile = ({ className }: Props) => {
  const { display } = useSearchDisplay();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Dialog
        open={display == 'classic' && isOpen}
        className='h-screen w-screen overflow-hidden rounded-none border-none bg-1-black p-4'
        closeButtonClassName='left-5 right-auto'
        onClose={() => {
          if (display == 'classic') {
            setIsOpen(false);
          }
        }}
      >
        <div className='mx-auto flex w-full items-center font-semibold'>
          <span className='ml-auto translate-x-6'>Filters</span>
          <AiSearch_SwitchDisplay className='ml-auto' />
        </div>

        <div className='scrollbar-hide h-full space-y-4 overflow-auto pb-32 pt-10'>
          <Filters_Calendar_Mobile />
          <Filters_Location_Mobile />
          <Filters_Guest_Mobile />
        </div>
        <Filters_Mobile_ButtonsBar />
      </Dialog>

      <Suspense>
        {isOpen && display == 'ai' ? (
          <AiSearch_Bar_Mobile
            className='fixed left-1/2 top-3 z-50 w-full max-w-[92vw] -translate-x-1/2 md:max-w-[601px]'
            onClear={() => setIsOpen(false)}
          />
        ) : null}
      </Suspense>

      {!isOpen ? (
        <button
          onClick={() => setIsOpen((isOpen) => !isOpen)}
          className={clx(
            'fixed left-1/2 top-3 flex h-[52px] w-full max-w-[92vw] -translate-x-1/2 items-center gap-3 overflow-hidden rounded-lg border border-w-8002 bg-1-black px-4 py-[6px] md:max-w-[601px]',
            className
          )}
        >
          <Icon name='wander-globe' className='top-auto h-7 w-[26.398px] text-white' stroke='none' />
          <div className='grid w-full grid-rows-2'>
            <div className='col-span-full text-left text-sm font-semibold max-[30px]:hidden'>
              Where do you want to Wander?
            </div>
            <div className='flex w-full min-w-0 gap-3'>
              <SearchDisplayAi or={<Classic />}>
                <Ai />
              </SearchDisplayAi>
            </div>
          </div>
        </button>
      ) : null}
    </>
  );
};

export default Filters_Mobile;
