import Icon from '@wander/ui/src/components/Icon';
import clx from '@wander/ui/src/utils/clx';

type Props = {
  icon: string;
  onClick: () => void;
  className?: string;
  active?: boolean;
};

const ButtonFilter = ({ icon, children, onClick, className, active = false }: React.PropsWithChildren<Props>) => (
  <div
    role='button'
    className={clx(
      'group isolate z-50 flex h-full min-w-[150px] flex-row items-center whitespace-nowrap border-w-8002 px-4 py-2 text-w-3005 transition-[background,min-width] duration-300 ease-out hover:text-white xl:min-w-[200px]',
      'group-[&[data-fixed="true"]]/body:min-w-[150px] group-[&[data-fixed="true"]]/body:xl:min-w-[150px]',
      'hover:!bg-[#292929]',
      className,
      active && 'bg-gradient-to-b from-[#292929] to-[#2E2E2E]'
    )}
    onClick={onClick}
  >
    <Icon name={icon} className='top-0 mr-3 text-w-4004 transition-colors duration-100 group-hover:text-inherit' />
    <div className='grid text-sm'>{children}</div>
  </div>
);

export default ButtonFilter;
