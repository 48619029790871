'use client';

import DropDownMenu from '@/widget/DropDownMenu';
import ButtonFilter from '../ButtonFilter';
import ButtonsBar from '../ButtonsBar';
import FilterLabel from '../FilterLabel';
import FilterPlaceholder from '../FilterPlaceholder';
import FilterValue from '../FilterValue';
import Filters_Guest_Form from './Filters_Guest_Form';
import useGuestFilter from './useGuestFilter';

type Props = {
  className?: string;
  collapsed?: boolean;
};

const Filters_Guest = ({ className, collapsed }: Props) => {
  const {
    isGuestOpen,
    toggleGuestFilter,
    resetFilters,
    handleGuestChange,
    handleSubmit,
    guests,
    adults,
    children,
    infants,
    pets,
  } = useGuestFilter();

  return (
    <>
      <ButtonFilter icon='people' onClick={toggleGuestFilter} className={className} active={isGuestOpen}>
        {!collapsed && <FilterLabel>Who</FilterLabel>}
        {guests ? (
          <FilterValue value={String(guests)} onClickClear={resetFilters} className='!justify-start' />
        ) : (
          <FilterPlaceholder label='Add guests' fixedLabel='Whoever' collapsed={collapsed} />
        )}
      </ButtonFilter>
      <DropDownMenu
        open={isGuestOpen}
        onClose={toggleGuestFilter}
        className='fixed left-1/2 top-1/2 z-[51] mx-auto w-max min-w-[383px] -translate-x-1/2 -translate-y-1/2 !animate-overlayShow p-3 sm:absolute sm:top-full sm:translate-y-6'
      >
        <Filters_Guest_Form
          onChange={handleGuestChange}
          adults={adults}
          // biome-ignore lint/correctness/noChildrenProp: it is children filter
          children={children}
          infants={infants}
          pets={pets}
        />

        <div className='mt-3 flex w-full items-center justify-between px-3 pb-3'>
          <ButtonsBar
            onClear={resetFilters}
            onSubmit={() => {
              toggleGuestFilter();
              handleSubmit();
            }}
          />
        </div>
      </DropDownMenu>
    </>
  );
};

export default Filters_Guest;
