'use client';

import AiSearch_DropdownList from '../AiSearch_DropdownList';
import { useAiSearchExample } from './AiSearch_ExampleContext';

type Props = {
  onClickSearch: (search: string) => void;
};

const AiSearch_Example = ({ onClickSearch }: Props) => {
  const [, ...examples] = useAiSearchExample();
  return <AiSearch_DropdownList title='EXAMPLE SEARCHES' items={examples} onClick={onClickSearch} />;
};

export default AiSearch_Example;
