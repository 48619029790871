'use client';

import { useSearchDisplay } from './SearchDisplayProvider';

const SearchDisplayAi = ({
  children,
  or = null,
}: React.PropsWithChildren<{
  or?: React.ReactNode;
}>) => {
  const { display } = useSearchDisplay();
  return display === 'ai' ? children : or;
};

export default SearchDisplayAi;
