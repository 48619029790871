import Icon from '@wander/ui/src/components/Icon';
import clx from '@wander/ui/src/utils/clx';

import AiSearch_Example from '../AiSearch_Example';
import AiSearch_Recent from '../AiSearch_Recent';

const AiSearch_SuggestPan = ({
  handleOnClickSearch,
  toggleDisplay,
  className,
}: {
  handleOnClickSearch: (search: string) => void;
  toggleDisplay?: () => void;
  className?: string;
}) => (
  <div
    className={clx(
      'scrollbar-hide absolute left-0 top-full z-50 grid max-h-80 w-full translate-y-4 divide-y divide-w-6003 overflow-auto rounded-lg border border-w-8002 bg-w-90015 transition-transform duration-300',
      className
    )}
  >
    <AiSearch_Recent onClickSearch={handleOnClickSearch} />
    <AiSearch_Example onClickSearch={handleOnClickSearch} />
    {toggleDisplay ? (
      <button
        onClick={toggleDisplay}
        type='button'
        className='sticky bottom-0 flex items-center justify-between bg-w-90015 p-3 text-left text-sm font-medium'
      >
        Switch to Traditional search <Icon name='reload' className='top-0 h-5 w-5 text-w-4004' />
      </button>
    ) : null}
  </div>
);

export default AiSearch_SuggestPan;
