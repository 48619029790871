import useLatestAiSearch from '../../../hooks/useLatestAiSearch';
import AiSearch_DropdownList from './AiSearch_DropdownList';

type Props = {
  onClickSearch: (search: string) => void;
};

const AiSearch_Recent = ({ onClickSearch }: Props) => {
  const { data } = useLatestAiSearch();

  if (!data?.length) {
    return null;
  }

  return <AiSearch_DropdownList title='RECENT SEARCHES' items={data} onClick={onClickSearch} />;
};

export default AiSearch_Recent;
