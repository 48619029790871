import { format } from 'date-fns';

const formatDate = (checkIn?: Date, checkOut?: Date) => {
  if (!checkIn || !checkOut) {
    return undefined;
  }

  const isSameMonth = checkIn.getMonth() === checkOut.getMonth();

  if (isSameMonth) {
    return `${format(checkIn, 'MMM d')} - ${format(checkOut, 'd')}`;
  }

  return `${format(checkIn, 'MMM d')} - ${format(checkOut, 'MMM d')}`;
};

export default formatDate;
