import client from '@/services/fetcher/client';
import yieldToMainThread from '@wander/ui/src/utils/yieldToMainThread';
import useSWR, { mutate } from 'swr';

const useLatestAiSearch = () =>
  useSWR('/guest-api/listings/search/latests', async () => {
    const recentUserSearches: string[] = await client
      .authFetch('/guest-api/listings/search/latests')
      .then((res) => res.json());

    await yieldToMainThread();

    if (recentUserSearches.length) {
      return recentUserSearches;
    }

    try {
      const localStorageRecentSearches = localStorage.getItem('recentSearches');
      const localRecentSearches = localStorageRecentSearches ? JSON.parse(localStorageRecentSearches) : [];
      return Array.isArray(localRecentSearches) ? localRecentSearches : [];
    } catch {
      return [];
    }
  });

export default useLatestAiSearch;

export const persistLocalSearch = (search: string) => {
  try {
    if (!search) {
      return;
    }

    const localStorageRecentSearches = localStorage.getItem('recentSearches');
    let localRecentSearches: string[] = localStorageRecentSearches ? JSON.parse(localStorageRecentSearches) : [];

    if (!Array.isArray(localRecentSearches)) {
      localRecentSearches = [];
    }

    if (localRecentSearches?.includes(search)) {
      return;
    }

    localRecentSearches = [search, ...localRecentSearches].slice(0, 5).filter(Boolean);
    localStorage.setItem('recentSearches', JSON.stringify(localRecentSearches));
    mutate('/guest-api/listings/search/latests', localRecentSearches);
  } catch {
    return;
  }
};
