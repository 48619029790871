'use client';

import { lazy } from 'react';
import Filters_Mobile_Details from '../Filters_Mobile_Details';
import useCalendar from './useCalendar';

const Calendar = lazy(() => import('@/features/booking/components/Calendar'));

const CalendarFilterMobile = () => {
  const { isCalendarOpen, toggleCalendarFilter, checkInDate, checkOutDate, dates, onChange } = useCalendar();

  return (
    <Filters_Mobile_Details
      isOpen={isCalendarOpen}
      onClick={toggleCalendarFilter}
      label='When'
      icon='calendar'
      value={dates ? dates : 'Add dates'}
    >
      <Calendar onChange={onChange} checkIn={checkInDate} checkOut={checkOutDate} />
    </Filters_Mobile_Details>
  );
};

export default CalendarFilterMobile;
